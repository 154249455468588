<template>
  <div class="signHelp h-p-10">
    <p class="h-text-center">合同签署</p>
    <div class="h-text-center h-p-10">
      <img :src="imgUrl()" />
    </div>
    <p class="h-font-md  h-p-10">方式一：请长按此二维码保存到手机相册(或点击<span class="h-primary-color h-p-l-2 h-p-r-2" @click="savePic">下载</span>)，并发送到微信中，识别此二维码，进入小程序签署合同。</p>
    <p class="h-font-md  h-p-10">方式二：您也可以在微信中搜索【鑫有所享】小程序，进入小程序签署合同。</p>
  </div>
</template>
<script>
export default {
  name: "CustomerSignHelp",
  components: {},
  data() {
    return {};
  },
  methods: {
    imgUrl() {
      if (process.env && process.env.NODE_ENV === "production") {
        return require("./img/sign_pro.png");
      } else if (process.env && process.env.NODE_ENV === "uat") {
        return require("./img/sign_uat.png");
      } else {
        return require("./img/sign_dev.png");
      }
    },
    savePic() {
      let blob = new Blob([""], { type: "application/octet-stream" }); // 二进制大型对象blob
      let url = URL.createObjectURL(blob); // 创建一个字符串路径空位
      let a = document.createElement("a"); // 创建一个 a 标签
      a.href = this.imgUrl(); // 把路径赋到a标签的href上
      // 正则表达式，这里是把图片文件名分离出来。拿到文件名赋到a.download,作为文件名来使用文本
      a.download = "鑫享服务小程序二维码";
      /* var e = document.createEvent('MouseEvents');  //创建事件（MouseEvents鼠标事件）
      e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null); //初始化鼠标事件（initMouseEvent已弃用） */

      // 代替方法。创建鼠标事件并初始化（后面这些参数我也不清楚，参考文档吧 https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/MouseEvent）
      let e = new MouseEvent("click", (true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null));
      // 派遣后，它将不再执行任何操作。执行保存到本地
      a.dispatchEvent(e);
      // 释放一个已经存在的路径（有创建createObjectURL就要释放revokeObjectURL）
      URL.revokeObjectURL(url);
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/assets/style/var.scss";
.signHelp {
  font-size: 18px;
  height: 100%;
}
</style>
